<template>
  <div class="badge">
    <div v-loading="loading"></div>
    <div class="badge-content" v-if="dataState">
      <div class="badge-list" v-for="(item, index) in resData.data" :key="index">
        <div class="badge-list-title" v-if="item.medalInfos.length">{{ item.title }}</div>
        <div class="badge-list-body">
          <div class="badge-item" v-for="itemChild in item.medalInfos" :key="itemChild.imgUrl">
            <img v-if="clientWidth > 768" :src="itemChild.imgUrl" />
            <img v-else :src="itemChild.imgUrl" @click="mobilePreview(itemImg)"/>
            <div class="badge-title mt10">{{ itemChild.title }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MyMedals } from '@/api/profile'
import { GetWxShares, GetShareData } from '@/utils/common'
import { mapState } from 'vuex'
import { ImagePreview } from 'vant'

export default {
  name: 'badge',
  data () {
    return {
      // ! 页面loading
      loading: true,
      // !接口返回状态
      dataState: false,
      resData: {
        data: [],
      },
    }
  },
  mounted () {
    const share = {
      title: '个人中心 | 剑盟社区',
      desc: ''
    }
    GetWxShares(share);
    GetShareData(1,'badge', '')
    this.GetInit()
  },
  methods: {
    GetInit () {
      MyMedals().then(res => {
        if(res.status === 1) {
          const { learnModel, examModel, activeModel } = res.data
          learnModel.title = '学习（' + learnModel.finish + ' / ' + learnModel.total + '）'
          examModel.title = '考试（' + examModel.finish + ' / ' + examModel.total + '）'
          activeModel.title ='活动'
          this.resData.data = [learnModel, examModel, activeModel]
          this.dataState = true
          this.loading = false
        } else {
          this.$toast(res.message)
        }
      })
    },
    // ! 移动端预览大图
    mobilePreview(images) {
      ImagePreview([images]);
    }
  },
  computed: {
    ...mapState(['clientWidth'])
  }
}
</script>

<style lang="scss" scoped>
  @import './index.scss';
</style>